import { useEffect, useRef } from "react";
import { logEvent } from "../Analytics/google-analytics";
import { useRouter } from "next/router";
import { imageBasePath } from "lib/utils/imageUtils";
import Image from "next/image";

const pressData = [
  {
    name: "Techcrunch",
    content: "Democratising student financing",
    img: "techcrunch1.jpg",
    url:
      "https://techcrunch.com/2020/03/03/leap-finance-indian-students-loans/",
  },
  {
    name: "ET",
    content: "Underwriting based on student potential and not family assets",
    img: "economic-times1.jpg",
    url:
      "https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/leap-finance-raises-5-5-million-led-by-sequoia-india/articleshow/74461279.cms",
  },
  {
    name: "Livemint",
    content: "A financing offer in 10 mins",
    img: "livemint1.jpg",
    url:
      "https://www.livemint.com/companies/start-ups/education-focused-lender-leap-finance-raises-5-5-million-led-by-sequoia-11583238918949.html",
  },
  {
    name: "Yourstory",
    content: "Innovating on customer experience and interest rates",
    img: "yourstory1.jpg",
    url:
      "https://yourstory.com/2020/03/funding-fintech-startup-leap-finance-sequoia-india-kunal-shah",
  },
  {
    name: "Inc42",
    content: "Fixing Student Loans For Studying Abroad",
    img: "inc421.jpg",
    url:
      "https://inc42.com/startups/leap-finance-gets-sequoia-backing-to-fix-loans-for-studying-abroad/",
  },
  {
    name: "TechAsia",
    content: "Complete study cost coverage, no collateral",
    img: "techinasia1.jpg",
    url:
      "https://www.techinasia.com/overseas-studentsfocused-lending-platform-leap-raises-55m-sequoialed",
  },
];

const PressResourcesCarousel = ({
  bankInfoVisible,
  setBankInfoVisible,
  showSubTitle = true,
  shouldCenterTitle = false,
  titleColor = "",
}) => {
  const router = useRouter();
  const pressSectionRef = useRef();
  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (!bankInfoVisible) {
          setBankInfoVisible && setBankInfoVisible(true);
          logEvent(
            "Bank-account",
            "Bank page modal opens on homepage",
            `${router.asPath} in homepage`
          );
        }
      }
    });
  };
  useEffect(() => {
    let observer;
    if (pressSectionRef.current) {
      let options = {
        root: null,
        threshold: 0,
      };
      observer = new IntersectionObserver(callback, options);
      observer.observe(pressSectionRef.current);
    }
    return () => {
      if (pressSectionRef.current) {
        observer.unobserve(pressSectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={pressSectionRef}
      className="py-12"
      style={{
        background: "url(/assets/images/about/green-grid.svg)",
      }}
    >
      <div className="max-w-page-width mx-auto px-8 xl:px-4">
        {showSubTitle && (
          <p className="leading-none text-primary text-xl md:pb-4 pb-2">
            Featured
          </p>
        )}
        <h2
          className={`leading-none lg:text-5xl md:text-4xl text-2xl font-bold pb-4 ${
            shouldCenterTitle ? "text-center" : ""
          } ${titleColor}`}
        >
          In the press
        </h2>
      </div>
      <div className="carousal-container xl:ml-40">
        {pressData.map((_o, idx) => (
          <div className="press-card" key={idx}>
            <a className="content" target="_blank" href={_o.url} alt={_o.name}>
              <Image
                src={`${imageBasePath}/assets/images/press/${_o.img}`}
                height={51}
                width={358}
                alt={_o.name}
                className="max-w-full h-auto"
              />
              <p className="text-gray-800 text-sm pt-4">" {_o.content} "</p>
            </a>
          </div>
        ))}
      </div>
      <style jsx>
        {`
          .carousal-container {
            overflow-x: scroll;
            margin-top: 1rem;
            display: -webkit-box;
            flex-wrap: nowrap;
            padding: 20px 0px;
          }
          .press-card {
            margin-left: 20px;
            width: 425px;
            height: 200px;
            border-left: 5px solid #23bab5;
            border-bottom: 5px solid #23bab5;

            @media (max-width: 767px) {
              width: 275px;
              height: 130px;
            }
          }
          .content {
            display: inline-block;
            width: 93%;
            height: 93%;
            background: white;
            border-radius: 4px;
            box-shadow: 0px 3px 6px #00000029;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            margin: auto;
            padding: 1rem;
            & > .lazy-load-image-background {
              width: 280px;
              height: 40px;
              @media (max-width: 767px) {
                width: 200px;
                height: 30px;
              }
            }
          }
        `}
      </style>
    </div>
  );
};

export default PressResourcesCarousel;
